<template>
  <div class="page-leaveteacher">
    <div class="tab-box">
      <div
        class="tab-item"
        v-for="(num, index) in tabs"
        :key="index"
        :class="{ active: index === tabNum }"
        @click="tabClick(index)"
      >
        {{ num }}
      </div>
    </div>
    <all v-if="tabNum === 0" />
    <pending v-if="tabNum === 1" />
    <processed v-if="tabNum === 2" />
    <received v-if="tabNum === 3" />
    <!-- <my v-if="tabNum === 1" />
    <add v-if="tabNum === 0" /> -->
    <!-- <audit v-if="tabNum === 1" /> -->
  </div>
</template>

<script>
import { Icon, Image, ImagePreview } from 'vant'
import All from './all.vue'
import Pending from './pending.vue'
import Processed from './processed.vue'
import Received from './received.vue'

export default {
  components: {
    All,
    Pending,
    Processed,
    Received
    
  },
  data () {
    return {
      tabs: ['全部','待处理','已处理','我收到的'],
      tabNum: 0
    }
  },
  mounted () {
    // this.getUser()
    if (this.$route.query.active) {
      this.tabNum = Number(this.$route.query.active)
    }
  },
  methods: {
    tabClick (index) {
      this.tabNum = index
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
