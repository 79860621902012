<template>
  <div class="page-folder">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div
              class="my-card"
              v-for="(item, i) in dataList"
              :key="i"
              @click="handleClick(item)"
            >
              <p class="p">请假宝贝：{{ item.ChildrenName }}</p>
              <!-- <p class="p">请假类型：{{ item.ChildrenLeaveTypeName }}</p> -->
              <div class="content-box">
                <div>
                  请假类型：<span>{{ item.ChildrenLeaveTypeName }}</span>
                </div>
                <div>
                  请假开始时间：<span>{{ item.BeginDate }}</span>
                </div>
                <div>
                  请假结束时间：<span>{{ item.EndDate }}</span>
                </div>
                <div>
                  请假天数：<span>{{ item.LeaveDays }}</span>
                </div>
                <!-- <div>
                  审核人：<span>{{ item.auditUserName }}</span>
                </div> -->
              </div>
              <!-- 审核状态 -->
              <template v-if="item.LeaveStateName === '正常'">
                <div
                  v-if="item.AuditStateName === '未审核'"
                  class="confirm-box"
                ></div>
                <div
                  v-if="item.AuditStateName === '通过'"
                  class="confirm-box type1"
                ></div>
                <div
                  v-if="item.AuditStateName === '驳回'"
                  class="confirm-box type2"
                ></div>
              </template>
              <template v-else>
                <div class="confirm-box type3"></div>
              </template>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import { Button, List, PullRefresh } from 'vant'
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  data () {
    return {
      userType: window.localStorage.getItem('UserType'),
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
      imgShow: false,
      images: []
    }
  },
  mounted () {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList () {
      this.page++
      this.getList()
    },
    getList () {
      const that = this
      that.$axios
        .get('/api/LeaveForChildren/AppForWorker', {
          page: this.page, // 页码
          limit: this.limit, // 每页条数
          isCopy:true
        })
        .then(res => {
          if (res.code === 200) {
            that.refreshing = false
            that.loading = false
            that.dataList = that.dataList.concat(res.data)
            if (that.dataList.length >= res.count) {
              that.finished = true
            }
          } else {
            that.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 下拉刷新
    onRefresh () {
      this.page = 0
      // 清空列表数据
      this.finished = false
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = []
      this.getDataList()
    },
    handleClick (item) {
      // 抄送人只能查看
      if (item.IsAduit) {
        if (item.AuditStateName === '未审核') {
          this.$router.push({
            path: 'LeaveBobyAuditEdit',
            query: {
              id: item.ID
            }
          })
        } else {
          this.$router.push({
            path: 'LeaveBobyAuditView',
            query: {
              id: item.ID
            }
          })
        }
      } else {
        this.$router.push({
          path: 'LeaveBobyAuditView',
          query: {
            id: item.ID
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page-folder {
  padding-top: 0px;

  .list-box {
    height: 75vh;
    overflow-y: auto;

    .my-card {
      position: relative;
      overflow: hidden;

      .p {
        color: #000000;
        font-size: 15px;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .content-box div:last-child{
        margin-bottom: 0px;
      }
      .content-box {
        //background: #f5f5f5;
        //padding: 10px;
        font-size: 14px;
        color: #999999;

        div {
          margin-bottom: 5px;

          span {
            color: #000000;
          }
        }
      }

      .confirm-box {
        position: absolute;
        right: 35px;
        top: 15px;
        width: 49px;
        height: 49px;
        background: url('../../../assets/icon/ic-audit0.png') center center no-repeat;
        background-size: 100% 100%;
      }

      .confirm-box.type1 {
        position: absolute;
        right: 35px;
        top: 15px;
        width: 49px;
        height: 49px;
        background: url('../../../assets/icon/ic-audit1.png') center center no-repeat;
        background-size: 100% 100%;
      }

      .confirm-box.type2 {
        position: absolute;
        right: 35px;
        top: 15px;
        width: 49px;
        height: 49px;
        background: url('../../../assets/icon/ic-audit2.png') center center no-repeat;
        background-size: 100% 100%;
      }

      .confirm-box.type3 {
        position: absolute;
        right: 35px;
        top: 15px;
        width: 49px;
        height: 49px;
        background: url('../../../assets/icon/ic-audit3.png') center center no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .add-btn {
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 99px;
    background-color: #ffe500;
    color: #000000;
    font-size: 36px;
    line-height: 50px;
    text-align: center;
    right: 10px;
    bottom: 60px;
  }
}
</style>
